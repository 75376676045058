import Vue from 'vue'
import App from './App.vue'
import VTooltip from 'v-tooltip'
import router from './router/index'
// @ts-ignore
import Vuesax from 'vuesax'
// @ts-ignore
import * as Sentry from "@sentry/vue";


import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
  colors: {
    primary:'#24B5C9',
    dark:'#ffffff',
  }
})

Vue.use(VTooltip)

Vue.prototype.$COMING_SOON = true;


Vue.prototype.$getLocation = async function() {
  if((<any>window)?.flutterChannel){
      // obtain location permission
      const result = await new Promise((resolve) => {
        (<any>window).getLocationResult = (res: any, err: any) => {
              if(res != null){
                res = JSON.parse(res);
              }
              console.log(err);
              resolve(res);
          };
          (<any>window)?.flutterChannel.postMessage(JSON.stringify({
              method: 'getLocation'
          }));

          setTimeout(() => {
            Sentry.captureException('Cannot get location permission: TIMEOUT (FlutterChannel))');
            resolve(null);
          }, 5000)
      });

      if(result == null){
          Sentry.captureException('Cannot get location permission (FlutterChannel))');
          return null;
      }

      return result;
  } else {
      // standard way
      return new Promise((resolve) => {
          window.navigator.geolocation.getCurrentPosition((d) => {
              resolve(d);
          }, (e) => {
              Sentry.captureException('Cannot get location permission: ' + e.message);
              resolve(null);
          });
      });
  }
}

import 'boxicons/css/boxicons.min.css';

import './assets/fonts/stylesheet.css';
import i18n from './i18n'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://d8355054a7c3495dcb789ca3408ee7fc@sentry.wised.it/2",
  environment: (<any>window).CONFIGURATION.IS_TEST ? 'TEST' : 'PROD', // production, test
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: (<any>window).CONFIGURATION.IS_TEST ? [window.location.origin] : [], // Allow all network requests to be captured
    })
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [window.location.origin],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default Vue;